import React from "react"

import Layout from "../layouts/layout"
import Seo from "../components/seo"
import Text from "../components/text"
import Headline from "../components/headline"
import Block from "../components/block"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404 Not Found" />
    <Block>
      <Headline text=":-(" primary />
      <Text>
        <p>404 Not Found</p>
      </Text>
    </Block>
  </Layout>
)

export default NotFoundPage
